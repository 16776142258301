require("turbolinks").start()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch"
import "element-closest-polyfill"
import "focus-visible/dist/focus-visible.min.js"
import CustomEvent from "custom-event-polyfill";
import "./scripts/mailchimp_validation"
import "./scripts/footnotes"
import "./scripts/browser_update"
import "./scripts/scrolling"
import "./scripts/plyr"



  import '/home/cristiano/Work/demsoc/app/components/core/header/navigation/navigation.js';

  import '/home/cristiano/Work/demsoc/app/components/public_square/search/search_component.js';

  import '/home/cristiano/Work/demsoc/app/components/public_square/cookies_bar/cookies-bar.js';

  import '/home/cristiano/Work/demsoc/app/components/search/search_component.js';

  import '/home/cristiano/Work/demsoc/app/components/cookies_bar/cookies-bar.js';

  import '/home/cristiano/Work/demsoc/app/components/button/button.js';

  import '/home/cristiano/Work/demsoc/app/components/map/map_slider/map-slider.js';

