import * as u from 'JS/utils'

u.domReady(function() {
  const elements = document.querySelectorAll('.icon-form-group')

  elements.forEach(function(el) {
    const input = el.querySelector('input[type=text]')
    const submit = el.querySelector('input[type=submit]')

    el.addEventListener('click', function(e) {
      if (e.target != submit) {
        input.focus()
      }
    })
  })
})

