import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
// configure Swiper to use modules
Swiper.use([Navigation]);

document.addEventListener('turbolinks:load', function() {
  const slideshowContainers = document.querySelectorAll('.swiper-container')
  slideshowContainers.forEach(function(container) {
  const slides = container.querySelectorAll('.swiper-slide');
    if (slides.length > 1) {
      const nextControl = container.querySelector('.map-slider__navigation-next')
      const prevControl = container.querySelector('.map-slider__navigation-prev')
      const mySwiper = new Swiper(container, {
        loop: true,
        watchOverflow: true,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: nextControl,
          prevEl: prevControl
        }
      })
    } else {
      container.querySelector('.map-slider__navigation-next').remove()
      container.querySelector('.map-slider__navigation-prev').remove()
    }
  })
})
