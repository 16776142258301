import * as u from 'JS/utils'

u.domReady(function() {
  const SmoothScroll = require("smooth-scroll")
  const scroll = new SmoothScroll("a.scrollto", {
    offset: 120
  });

  const codebookScroll = new SmoothScroll("a.scrollto-codeword");

  document.addEventListener("turbolinks:load", function() {
    window.scrollTo(0, 0)
  });

  // scroll to element when url contains hash
  // if (window.location.hash) {
  //   const manualScroll = new SmoothScroll();
  //   const hash = window.location.hash;
  //   const el = document.querySelector(hash)
  //
  //   if (el != undefined) {
  //     manualScroll.animateScroll(el, null, {offset: 120});
  //   }
  // }
})

