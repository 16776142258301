import Plyr from 'plyr';

document.addEventListener("turbolinks:load", function() {
  const videos = document.querySelectorAll('.gallery-video');
  
  Array.prototype.forEach.call(videos, function(video) {
    const player = new Plyr(video);
  })
});

