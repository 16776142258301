/*
 * Target buttons created in megadraft editor.
 *
 * I didn't have access to the inner text of the element selected in
 * the draftjs render lifecycle. I opted to be a js-ninja instead.
 *
*/

document.addEventListener('turbolinks:load', function() {
  const dumb_buttons = document.querySelectorAll('.button:not([aria-label])');
  if (dumb_buttons) {
    dumb_buttons.forEach(function(button) {
      button.setAttribute('aria-label', button.innerText)
    })
  }

  const dumb_content_buttons = document.querySelectorAll('.content-button:not([aria-label])');
  if (dumb_content_buttons) {
    dumb_content_buttons.forEach(function(button) {
      button.setAttribute('aria-label', button.innerText)
    })
  }
})

