import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "gallery", "image" ]

  initialize() {
    let carouselIsActive = false
  }

  imageClickHandler(e) {

    this.toggle()

    if (this.carouselIsActive) {
      this.index = parseInt(e.currentTarget.dataset.index)
      this.showCurrentSlide(this.index)
    }
  }

  toggle() {
    this.carouselIsActive = !this.carouselIsActive
    this.galleryTarget.classList.toggle("grid")
    this.galleryTarget.classList.toggle("lightbox")

    this.galleryTarget.classList.add("lightbox-init")
    setTimeout(() => {
      this.galleryTarget.classList.remove("lightbox-init")
    }, 200);
  }

  previous() {
    this.index--
  }

  next() {
    this.index++
  }

  showCurrentSlide(index) {
    index = this.validateIndex(index)

    Array.prototype.forEach.call(this.imageTargets, (image, i) => {
      if (index == i) {
        image.classList.add("active")
      } else {
        image.classList.remove("active")
      }
    })
  }

  validateIndex(value) {
    value >= this.imageTargets.length ? value = 0 : value
    value < 0 ? value = this.imageTargets.length - 1 : value
    return value
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    value = this.validateIndex(value)

    this.data.set("index", value)
    this.showCurrentSlide(value)
  }
}
