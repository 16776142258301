import * as u from '../utils'

u.domReady(function() {
  const elements = document.querySelectorAll('.footnote-inline')
  const parent = document.querySelector('.generic-content')

  if (elements.length == 0 || parent == null) return;

  let wrapper = document.createElement('div')
  wrapper.classList.add( 'footnote' )

  Array.from(elements).forEach(function(el, i) {
    const id = i+1
    const data = el.dataset.footnoteContent

    let link = document.createElement('a')
        link.href = '#footnote_' + id
        link.classList.add( 'footnote-inline__link', 'scrollto' )
        link.innerHTML = id

    let note = document.createElement('div')
        note.id = 'footnote_' + id
        note.classList.add( 'footnote__item' )
        note.innerHTML = '<b>[' + id + ']</b> ' + data

    el.appendChild(link)
    wrapper.appendChild(note)
  })

  parent.appendChild(wrapper)
})
