function isIE() {
  ua = navigator.userAgent;
  var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
  return is_ie;
}

if (!isIE()){
  const alert = document.getElementById('ieAlert')
  if (alert) {
    alert.remove()
  }
}
