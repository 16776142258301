import { Controller } from 'stimulus'
import querystring from 'querystring'
import axios from 'axios'
import * as u from 'JS/utils'

export default class extends Controller {
  static targets = ['form', 'input', 'submit', 'reset']

  connect() {
    for (const i in this.inputTargets) {
      if (this.inputTargets[i].selectedIndex > 0) {
        this.filter()
        break
      }
    }
    this.submitTarget.classList.add('visually-hidden')
  }

  getResults(filters, page) {
    const params = Object.assign(filters, {page: page})
    const output = document.querySelector(this.data.get('output'))
    const paginator = document.querySelector(this.data.get('paginator'))

    axios.get(this.data.get('url'), {
      params,
      responseType: 'text',
      paramsSerializer: params => querystring.stringify(params)
    })
    .then(function (response) {
      if (page > 1) {
        paginator.remove()
        const currentItems = output.innerHTML
        output.innerHTML = currentItems + response.data
      } else {
        output.innerHTML = response.data
      }
    })
    .catch(function (error) {
      console.log(error)
    })
  }

  getOptions() {
    const params = {}
    let anySelected = false
    this.inputTargets.forEach(input => {
      anySelected = anySelected ? true : input.selectedIndex > 0 ? true : false
      Object.assign(params, {[input.name]: input.value})
    })
    this.toggleResetButton(anySelected)
    return params
  }

  toggleResetButton(selected) {
    this.resetTarget.classList.toggle('visually-hidden', !selected)
  }

  filter() {
    this.getResults(this.getOptions(), 1)
  }

  loadMore(e) {
    e.preventDefault()
    this.getResults(this.getOptions(), e.currentTarget.dataset.page)
  }
}
