import { Controller } from 'stimulus'
import AddSearchClient from "addsearch-js-client";

const PAGE_LIMIT = 10
const ENABLED_CLASS = 'enabled'

export default class extends Controller {
  static targets = [
    'paginationTemplate', 'cardTemplate', 'summaryTemplate',
    'paginationOutput', 'cardOutput', 'input',
    'nextControl', 'previousControl', 'clearControl', 'controls'
  ]

  initialize() {
    this.client = new AddSearchClient("6c62172c8a8fe4c000c27bba16202d07")
    this.client.setPaging(1, PAGE_LIMIT, "relevance", "desc")
    this.client.setFuzzyMatch(false);
  }

  connect() {
    const params = new URLSearchParams(window.location.search)
    this.data.set('currentQuery', params.get('query'))
    this.inputTarget.value = params.get('query')
    this._search()
  }

  submit(event) {
    event.preventDefault()
    this.client.setPaging(1, PAGE_LIMIT, "relevance", "desc");
    const query = this.inputTarget.value
    this.data.set('currentQuery', query)
    this._search()
  }

  clear() {
    this.data.delete('currentQuery')
    this._clearPaginator()
    this._clearControls()
    this._clearOutput()
    this._clearInput()
  }

  previous() {
    this.client.previousPage()
    this._search()
  }

  next() {
    this.client.nextPage()
    this._search()
  }

  _search() {
    if (this.data.get('currentQuery') == 'null') {
      this.clear()
    } else {
      this.client.search(this.data.get('currentQuery'), res => {
        this.data.set('currentPage', res.page)
        this.data.set('currentHits', res.total_hits)
        this.data.set('totalPages', Math.ceil(res.total_hits / PAGE_LIMIT))
        this._renderControls()
        this._renderSummary()
        this._clearOutput()
        res.hits.forEach((result, index) => {
          this._renderResult(result)
        })
      })
    }
  }

  _renderResult(item) {
    const card = this.cardTemplateTarget.content.cloneNode(true)
    card.querySelector('h2').innerText = item.title
    card.querySelector('p').innerHTML = item.meta_description
    card.querySelector('a').setAttribute('href', item.url)
    this.cardOutputTarget.appendChild(card)
  }

  _renderControls() {
    this.clearControlTarget.classList.toggle(ENABLED_CLASS, this.data.get('currentQuery'))
    this.previousControlTarget.classList.toggle(ENABLED_CLASS, this.currentPage > 1)
    this.nextControlTarget.classList.toggle(ENABLED_CLASS, this.currentPage < this.totalPages)
  }

  _renderSummary() {
    this._clearPaginator()

    const summaryTemplate = this.summaryTemplateTarget.content.cloneNode(true)
    summaryTemplate.querySelector('#query').innerText = this.data.get('currentQuery')
    summaryTemplate.querySelector('#total').innerText = this.data.get('currentHits')
    this.paginationOutputTarget.appendChild(summaryTemplate)

    const paginationTemplate = this.paginationTemplateTarget.content.cloneNode(true)
    paginationTemplate.querySelector('#current_page').innerText = this.data.get('currentPage')
    paginationTemplate.querySelector('#total_pages').innerText = this.totalPages
    if (this.totalPages > 0 && this.currentPage != this.totalPages) {
      this.paginationOutputTarget.appendChild(paginationTemplate)
    }
  }

  get currentHits() {
    return parseInt(this.data.get('currentHits'))
  }

  get currentPage() {
    return parseInt(this.data.get('currentPage'))
  }

  get totalPages() {
    return parseInt(this.data.get('totalPages'))
  }

  _clearOutput() {
    const output = this.cardOutputTarget
    while (output.firstChild) {
      output.removeChild(output.lastChild)
    }
  }

  _clearPaginator() {
    const output = this.paginationOutputTarget
    while (output.firstChild) {
      output.removeChild(output.lastChild)
    }
  }

  _clearControls() {
    this.controlsTarget.querySelectorAll(`.${ENABLED_CLASS}`).forEach(control => {
      control.classList.remove(ENABLED_CLASS)
    })
  }

  _clearOutput() {
    const output = this.cardOutputTarget
    while (output.firstChild) {
      output.removeChild(output.lastChild)
    }
  }

  _clearInput() {
    const input = this.inputTarget
    input.value = ''
  }
}
